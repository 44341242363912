@import "./base/variables";
@import "./base/fonts";
@import "./base/reset";
@import "./base/mixins";
@import "../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

* {
  scrollbar-width: thin;
  scrollbar-color: $white $darkblue;
}
*::-webkit-scrollbar {
  width: 1rem;
}
*::-webkit-scrollbar-track {
  background: $darkblue;
}
*::-webkit-scrollbar-thumb {
  background-color: $white;
  border-radius: 0;
  border: 0.3rem solid $darkblue;
}

.hamburger {
  outline: none;
  position: absolute;
  // top: 56px;
  // right: 13%;
  top: 39px;
  right: 3%;
  z-index: 10000;
}
/* Global styles */
.container {
  &--slim {
    max-width: 1426px;
    width: 74%;
    min-height: 100px;
  }
  &--wide {
    width: 95%;
    min-height: 100px;
  }
  margin: 0 auto;
}
h1 {
  @include light(7.5rem, 8.5rem, $black);
  width: 60%;
  margin-bottom: 30px;
}
h3 {
  width: 60%;
}
.container > img {
  @include responsive();
}
p.date {
  @include bold(4rem, 4rem, $black);
}

.linkedZone {
  margin: 170px 0;
  background-color: $brown;
  padding: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16rem;
  &.vacature {
    display: block;
  }
  p {
    width: 100% !important;
    &.title {
      @include bold(3.5rem, 4.5rem, $darkblue);
    }
    margin: 0;
  }
  a {
    @include bold(3.5rem, 4rem, $darkblue);
    white-space: nowrap;
    &:hover {
      text-decoration: underline;
    }
  }
}
.team {
  @include make-col(4, 88px);
  margin-top: 120px;
  .lid {
    margin-bottom: 90px;
    img {
      @include responsive();
      border-radius: 50%;
    }
    p {
      text-align: center;
      margin: 0;
      width: 100%;
      &.naam {
        @include heavy();
        margin-top: 10px;
      }
      &.functie {
        @include light(3rem);
        margin-bottom: 20px;
      }
      a {
        @include light(2.7rem);
        &:hover {
          text-decoration: underline;
        }
      }
    }
    ul {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      li {
        margin: 0 15px;
        a {
          display: block;
          width: 33px;
          height: 33px;

          &.linkedin {
            @include icon("\f0e1", $darkblue);
            &:after {
              top: 0rem;
              left: 0rem;
            }
            &:hover:after {
              color: $black;
            }
          }
          &.twitter {
            @include icon("\f099", $darkblue);
            &:after {
              top: 0rem;
              left: 0rem;
            }
            &:hover:after {
              color: $black;
            }
          }
          &.facebook {
            @include icon("\f09a", $darkblue);
            &:after {
              top: 0rem;
              left: 0rem;
            }
            &:hover:after {
              color: $black;
            }
          }
        }
      }
    }
  }
}
.content {
  margin-top: 100px;
  margin-left: 16rem;
  margin-right: 16rem;
  ul {
    margin-left: 30px;
    li {
      list-style-type: disc;
    }
  }
  p {
    width: 100% !important;
    strong {
      font-weight: 600;
    }
    em {
      font-style: italic;
    }
    u {
      text-decoration: underline;
    }
  }
  h1 {
    width: 100%;
    @include bold(4rem, 5rem, $black);
  }
  h3 {
    font-family: trade-gothic-next, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 3.6rem;
    line-height: 4.5rem;
    color: #000;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

h2 {
  position: relative;
  text-transform: uppercase;
  font-size: 4.5rem;
  color: black;
  z-index: 0;
  margin-bottom: 6.5rem;
  margin-top: 12rem;
  line-height: 5rem;
  &::after {
    content: "";
    position: absolute;
    display: block;
    z-index: -1;
    left: -4rem;
    top: 2rem;
    width: 54.3rem;
    height: 7.2rem;
    background-color: $brown;
  }
}

.centered {
  text-align: center;
}

a.button {
  height: 7.2rem;
  line-height: 6.4rem;
  font-size: 3rem;
  color: $black;
  padding-left: 7rem;
  padding-right: 2rem;
  border: 1px solid $darkblue;
  display: inline-block;
  margin-top: 5rem;
  background-color: $white;
  transition: all 300ms;
  @include icon("\e800", $darkblue);
  &:after {
    font-size: 4.2rem;
  }
  &:hover {
    color: $white;
    background-color: $darkblue;
    &:after {
      color: $white;
    }
  }
}
centered {
  text-align: center;
}
p {
  margin: 4rem 0;
}

#intro {
  background-size: cover;
  background-position: center;
  height: 100vh;
  background-image: url("../images/bg.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
  h3,
  h1,
  h5 {
    @include light(7.8rem, 8rem, $white);
    text-align: center;
    width: 100%;
    br {
      display: none;
    }
  }
  h1 {
    font-size: 4.7rem;
  }
  h5 {
    font-size: 3.7rem;
    margin-bottom: 10px;
  }
  a.circle {
    @include icon("\e800", $white);
    &:after {
      font-size: 10rem;
    }
    position: absolute;
    left: calc(50% - 35px);
    bottom: 150px;
  }
}
lottie-player {
  cursor: pointer;
}
.hamburger-label {
  position: absolute;
  bottom: -34px;
  left: 19px;
  text-align: center;
  color: #fff;
  font-family: sans-serif;
  // text-transform: uppercase;
  font-size: 2.8rem;
}
.hamburger.is-active .hamburger-label {
  display: none;
}
.hamburger.is-active {
  right: 1%;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: $darkblue;
}
.cd-panel__container {
  background-color: $white;
}
footer {
  background-color: $darkblue;
}
.cd-panel__content {
  padding: 50px 5% 20px 5%;
  ul {
    li {
      @include light(6rem, 13rem, $darkblue);

      border-bottom: 1px solid $darkblue;
      margin-top: -5px;
      text-transform: uppercase;
      width: 54%;
      margin-left: 12%;
      margin-top: 0px;
      text-transform: none;
      width: 54%;
      margin-left: 12%;
      &:last-child {
        border-bottom: 0;
      }
      a {
        color: $darkblue;
        display: block;
        @include icon("\e800", $darkblue);
        white-space: nowrap;
        &:after {
          left: auto;
          right: 0;
          font-weight: normal;
          display: none;
          font-size: 6rem;
        }
        &:hover {
          text-decoration: underline;
          font-weight: 800;
          &:after {
            display: block;
          }
        }
      }
    }
  }
}

.block1 {
  margin-top: 100px;
  margin-bottom: 100px;
  p {
    width: 68%;
  }
}

.block2 {
  @include make-col(3, 70px);
  align-items: flex-start;
}

.block3 {
  p {
    width: 68%;
  }
  .services {
    ul {
      margin-top: 100px;
      margin-bottom: 120px;
      display: flex;
      justify-content: space-between;
      li {
        text-align: center;
        a {
          font-size: 4.9rem;
          @include icon("\e800", $darkblue);
          color: $darkblue;
          img {
            display: inline-block;
            transform: scale(0.8);
          }
          &:after {
            top: auto;
            left: 35.4px;
            bottom: -66px;
            font-size: 7rem;
          }
        }
      }
    }
  }
}

.block4 {
  margin-top: 200px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 120px;
  img {
    @include responsive();
    width: 60%;
  }
  .quote {
    position: relative;
    padding-top: 140px;
    &:before {
      content: "";
      background-image: url("../images/quote.png");
      position: absolute;
      left: -12px;
      width: 108px;
      height: 91px;
      top: -8px;
    }
    width: 37%;
  }
}
.nieuws-item {
  position: relative;
  a.button {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  padding-bottom: 100px;
}
.block5 {
  margin-bottom: 120px;
  .nieuws {
    @include make-col(3, 70px);
    .date {
      @include bold(3rem, 2.5rem, $black);
      margin-top: 1rem;
      margin-bottom: 0;
    }
    .titel {
      @include light(3.5rem, 5rem, $black);
      margin-top: 7px;
      margin-bottom: 0;
    }
    .text {
      @include light(3rem, 4rem, $black);
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
}

input[type="checkbox"] {
  display: none;
}
input[type="checkbox"].error + label,
input[type="email"].error,
input[type="text"].error,
textarea.error {
  border-radius: 0;
  border-color: red !important;
  color: red !important;
  &::placeholder {
    color: red !important;
  }
  a {
    color: red !important;
  }
}
input[type="checkbox"] + label {
  @include light(2.8rem, 4rem, $darkblue);
  cursor: pointer;
  a {
    color: $darkblue;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
h2.swal2-title {
  @include bold(3.5rem, 4.5rem, $darkblue);
  &:after {
    display: none;
  }
}
.swal2-html-container {
  @include light(2.5rem, 3.5rem, $black);
}
.swal2-styled.swal2-confirm {
  border: 0 !important;
  border-radius: 0 !important;
  background-color: $darkblue !important;
  font-family: trade-gothic-next, sans-serif !important;
  font-weight: 300 !important;
  font-style: normal !important;
  font-size: 2rem !important;
}
input[type="checkbox"] + label span {
  display: inline-block;
  width: 33px;
  height: 34px;
  margin: -19px 17px 0 0;
  vertical-align: middle;
  background: url("../images/unchecked.png") left top no-repeat;
  cursor: pointer;
}
input[type="checkbox"]:checked + label span {
  background: url("../images/checked.png") left top no-repeat;
}

.nieuwsbrief {
  width: 75%;
  margin-bottom: 75px;
  h1 {
    @include heavy(4.5rem, 5.5rem, $darkblue);
    margin-bottom: -40px;
  }
  input[type="text"] {
    display: block;
    width: 100%;
    height: 70px;
    margin-top: 80px;
    border-bottom: 2px solid $black;
    margin-bottom: 30px;
    border-radius: 0;
  }

  a.button {
    margin-top: 0rem;
    margin-left: 3rem;
  }

  form {
    .flexed {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
}

footer {
  background-color: $darkblue;
  min-height: 250px;
  padding-bottom: 50px;
  .container {
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
    ul:nth-child(2) {
      margin-right: 100px;
    }
    li {
      a {
        @include light(2.8rem, 5rem, $white);
        text-transform: uppercase;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    p {
      margin-top: 0;
      @include light(2.8rem, 5rem, $white);
      a {
        color: $white;
        &:hover {
          text-decoration: underline;
        }
        &.linkedin {
          @include icon("\f0e1", $white);
          &:after {
            left: 0.2rem;
            font-size: 3.8rem;
          }
        }
      }
    }
  }
  p.copy {
    margin: 0;
    @include light(2rem, 5rem, $white);
    text-align: center;
    a {
      color: $white;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.globalcookie {
  opacity: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $darkblue;
  padding: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
  box-shadow: 0 -0.3rem 0.6rem #00000029;
  &.hidden {
    display: none;
  }
  .cookieinfo {
    margin-right: 40px;
    h5 {
      @include light(3.5rem, 7rem, $darkblue);
      font-size: 3.5rem;
      color: $white;
      margin-bottom: 17px;
    }
    p {
      @include light(2rem, 4rem, $darkblue);
      font-size: 2rem;
      color: $white;
    }
  }
  a.button {
    &:after {
      display: none;
    }
  }
}

header {
  height: 195px;
  display: flex;
  background-color: $darkblue;
  align-items: center;
  & > a {
    @include light(2.5rem, 0rem, $lightblue);
    // text-transform: uppercase;
    transition: all 300ms;
    margin-left: 6rem;
    &:hover {
      color: $white;
    }
  }
  div {
    margin: 0 auto;
    h5,
    h6 {
      text-align: center;
      @include light(4.5rem, 2.3rem, $white);
    }
    h5,
    h6,
    h4 {
      font-family: trade-gothic-next, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 3.2rem;
      line-height: 7rem;
      color: #fff;
      text-align: center;
      width: 100%;
    }
    h5 {
      font-size: 5rem;
    }
    h4 {
      font-size: 2.4rem;
      margin-bottom: 0px;
      line-height: 5.5rem;
    }
  }
  ul {
    margin-right: 6rem;
    display: flex;
    visibility: hidden !important;
    li {
      margin-right: 1.6rem;
      a {
        @include light(2.5rem, 0rem, $lightblue);
        text-transform: uppercase;
        transition: all 300ms;
        &.active,
        &:hover {
          color: $white;
        }
      }
    }
  }
}
textarea {
  border: 2px solid $black;
  width: 100%;
  height: 180px;
  resize: none;
  display: block;
  padding: 10px;
}
.contactend {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .button {
    margin-top: 0;
  }
  margin-bottom: 50px;
}
input[type="checkbox"] {
  border-radius: 0;
  width: 30px;
  height: 30px;
  border: 2px solid #000;
  outline: none;
}
.cols-2 {
  margin-top: 80px;
  @include make-col(2, 100px);

  &.fields {
    @include make-col(2, 50px);
  }
  input {
    border-bottom: 2px solid $black;
    padding: 10px;
    padding-left: 0;
  }

  h2:first-child {
    // margin-top: -0.7rem;
    margin-top: 0;
  }
  &.contact {
    h2 {
      line-height: 6rem;
      text-transform: initial;
      &:after {
        top: auto;
        bottom: -2.8rem;
        width: 54.3rem;
        height: 6rem;
      }
    }
  }
  img {
    @include responsive();
    object-fit: cover;
  }
  p {
    width: 100%;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 4rem;
  }
  h4 {
    @include light(5rem, 5.5rem, $black);
    margin-bottom: 4rem;
  }
}
.cols-3 {
  margin-top: 100px;
  @include make-col(3, 40px);
  .col {
    background-color: $brown;
    padding: 14px;
    position: relative;
    &.hidden {
      display: none;
    }
    img {
      @include responsive();
    }
    & > div {
      padding: 0 22px 50px 22px;
      p {
        width: 100%;
        margin-right: 0;
      }
      p.date {
        @include bold(2rem, 5rem, $darkblue);
      }
      p.title {
        @include light(3.5rem, 4rem, $black);
      }
      p {
        @include light(2.5rem, 4rem, $black);
      }
      a.button {
        position: absolute;
        bottom: 22px;
        left: 36px;
      }
    }
  }
}
.breadCrumb {
  margin-bottom: 60px;

  a {
    border-bottom: 1px solid $black;
    &:hover {
      border-bottom: 0px solid $black;
    }
    text-transform: capitalize;
  }
  span {
    text-transform: capitalize;
  }
}
@media screen and (max-width: 1500px) {
  .container {
    &--slim {
      width: 85%;
    }
  }
  .hamburger-label {
    left: 18px;
  }
  .block3 .services ul li a:after {
    font-size: 4.3rem;
  }
}

@media screen and (max-width: 1400px) {
  .block3 .services ul li a:after {
    font-size: 5.2rem;
  }
}
@media screen and (max-width: 1100px) {
  .hamburger {
    //top: 56px;
    top: 39px;
    right: 27px;
  }
  header > a {
    display: none;
  }
  header div h5,
  header div h6,
  header div h4 {
    text-align: left;
    margin-left: 30px;
  }
  header div {
    margin: 0;
    margin-top: 42px;
  }
  header {
    justify-content: space-between;
    align-items: flex-start;
  }
  header ul {
    margin-right: 30px;
  }
  .container--slim {
    width: calc(100% - 60px);
  }
  .team {
    @include make-col(3, 60px);
  }
}
@media screen and (max-width: 1000px) {
  .block2 {
    @include make-col(3, 30px);
  }
  footer .container ul:nth-child(2) {
    margin-right: 30px;
  }
  .linkedZone {
    margin: 60px 0;
    margin-right: 0;
  }
  .nieuwsbrief h1 {
    width: 100%;
    line-height: 5rem;
  }
  .nieuwsbrief {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .container {
    &--slim {
      width: 96%;
    }
  }
  .cols-2 {
    @include make-col(1, 50px);
    img:first-child {
      order: 2;
    }
  }
  h1,
  h3 {
    width: 100%;
  }
  .cols-3 {
    @include make-col(2, 50px);
  }
}

@media screen and (max-width: 800px) {
  .block1 .content {
    margin-top: 100px;
    margin-left: 0rem;
    margin-right: 0rem;
    text-align: left;
    p {
      text-align: left;
    }
  }
  .linkedZone {
    flex-direction: column;
  }
  .nieuws-item p {
    text-align: center;
  }
  .nieuws-item a.button {
    position: relative;
  }
  .nieuwsbrief a.button {
    width: 100%;
  }
  .nieuws-item {
    padding-bottom: 0px;
  }
  .breadCrumb {
    display: none;
  }
  h1,
  h3 {
    width: 100%;
  }
  header {
    height: 137px;
  }
  .team {
    @include make-col(2, 50px);
  }
  header ul {
    margin-right: 14px;
    margin-top: 10px;
  }
  header div {
    margin: 0;
    margin-top: 2rem;
  }
  header div h5,
  header div h4,
  header div h6 {
    line-height: 6rem;
  }

  .hamburger-label {
    display: none;
  }
  .hamburger {
    right: 1%;
  }
  #intro h3 br {
    display: block;
  }
  .block1 {
    text-align: center;
    p {
      width: 100%;
      text-align: center;
    }
  }
  .block2 {
    width: 100%;
    img:nth-child(2),
    img:nth-child(3) {
      display: none;
    }
    @include make-col(1, 0px);
  }
  .block3 p {
    width: 100%;
  }
  .block3 .services ul {
    flex-wrap: wrap;
    margin-bottom: 20px;
    li {
      width: 50%;
      margin-bottom: 80px;
    }
  }
  .block3 .services ul li a:after {
    bottom: -40px;
  }
  .block3 .services ul li a:after {
    font-size: 5rem;
  }
  .block4 {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 100px;
    margin-bottom: 30px;
    img {
      width: 100%;
    }
    .quote {
      width: 96%;
      margin-left: 2%;
      padding-top: 90px;
      &:before {
        top: 22px;
      }
    }
  }
  .block5 {
    margin-bottom: 50px;
    .nieuws {
      text-align: center;
      @include make-col(1, 70px);
      .date {
        margin-top: 20px;
        text-align: center;
      }
      .titel {
        text-align: center;
      }
      .text {
        text-align: center;
      }
    }
  }

  .nieuwsbrief {
    width: 100%;
    input[type="text"] {
      margin-top: 20px;
    }
  }

  footer .container {
    @include make-col(1, 30px);
    text-align: center;
  }
  footer .container ul:nth-child(1) {
    margin-bottom: 0;
  }
  footer .container ul:nth-child(2) {
    margin-right: 0px;
  }
  footer p {
    padding-top: 30px;
    border-top: 1px solid $white;
    width: 40% !important;
    margin-left: 30% !important;
    margin-right: 30% !important;
  }
  footer .container p a.linkedin:after {
    left: -1.5rem;
  }
  .nieuwsbrief form .flexed {
    align-items: flex-start;
    flex-direction: column;
  }
  .nieuwsbrief a.button {
    margin-top: 4rem;
    margin-left: 0rem;
  }
  header div h4 {
    font-size: 2rem;
  }
  header div h5 {
    font-size: 4rem;
  }
  header div h6 {
    font-size: 3rem;
  }
  h2:after {
    left: calc(50% - 27.15rem);
  }
}

@media screen and (max-width: 800px) {
  .block1 {
    margin-top: 70px;
  }
  .block3 .services ul {
    li {
      width: 100%;
      margin-bottom: 100px;
    }
  }
  html {
    font-size: 38%;
  }
  #intro a.circle:after {
    left: 0rem;
    font-size: 10rem;
  }
  #intro a.circle {
    left: calc(50% - 21.5px);
  }
  .block3 .services ul li a:after {
    font-size: 8rem;
  }
  .cd-panel__content ul li {
    font-size: 4.3rem;
    line-height: 10rem;
    margin-top: -5px;
    width: 59%;
    margin-left: 12%;
  }
  #intro h3 {
    font-size: 6.8rem;
  }
  #intro h1 {
    font-size: 3.4rem;
  }
  a.button {
    height: 8.1rem;
    line-height: 7rem;
    font-size: 3.2rem;
    &:after {
      top: 0.5rem;
      left: 2.6rem;
      font-size: 4rem;
    }
  }
  .block5 .nieuws .date {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 700px) {
  .team {
    @include make-col(1, 50px);
  }
  .cols-3 {
    @include make-col(1, 50px);
  }
  .team .lid img {
    padding: 0 50px;
  }
  header div h5,
  header div h6 {
    line-height: 4rem;
    margin-top: 12px;
  }
  header div h4,
  header div h6 {
    display: none;
  }
  header div h5 {
    margin-top: 32px;
  }
  header div {
    margin-top: 20px;
  }
  .cols-2.fields {
    @include make-col(1, 50px);
  }
}

@media screen and (max-width: 600px) {
  html {
  }
  footer p {
    width: 100% !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
}
