@mixin responsive {
  display: block;
  height: auto;
  width: 100%;
}

@mixin fix {
  &::after {
    clear: both;
    content: "";
    display: block;
  }
}
@mixin icon($content, $color) {
  position: relative;
  &:after {
    content: $content;
    font-family: "icon";
    position: absolute;
    color: $color;
    transition: all 300ms;
    top: 0.4rem;
    left: 2.6rem;
    font-size: 3.8rem;
  }
}
@mixin make-col($cols, $gap) {
  display: flex;
  flex-wrap: wrap;

  > *:nth-child(1n + 0) {
    margin: 0 $gap $gap 0;
    width: calc((100% - #{($cols - 1) * $gap}) / #{$cols});
  }

  > *:nth-child(#{$cols}n + 0) {
    margin-right: 0;
  }
}

@mixin make-col-old($cols: 4, $gap: 20px) {
  @include fix;

  > * {
    display: block;
    float: left;
    margin-bottom: $gap;
    margin-right: $gap;
    min-height: 50px;
    width: calc((100% - #{($cols - 1) * $gap}) / #{$cols});
  }

  > *:nth-child(#{$cols}n + 0) {
    margin-right: 0;
  }
}
