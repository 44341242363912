//@import url("https://fonts.googleapis.com/css?family=Charmonman");
@import "./icon/css/icon.css";

@mixin light($fs: 3rem, $lh: 4rem, $color: #000) {
  font-family: trade-gothic-next, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: $fs;
  line-height: $lh;
  color: $color;
}

@mixin italic($fs: 3rem, $lh: 4rem, $color: #000) {
  font-family: trade-gothic-next, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: $fs;
  line-height: $lh;
  color: $color;
}

@mixin heavy($fs: 3rem, $lh: 4rem, $color: #000) {
  font-family: trade-gothic-next, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: $fs;
  line-height: $lh;
  color: $color;
}

@mixin bold($fs: 3rem, $lh: 4rem, $color: #000) {
  font-family: trade-gothic-next, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: $fs;
  line-height: $lh;
  color: $color;
}
